
import moment from "moment";
import axios, { AxiosError } from "axios";
import { defineComponent, onBeforeMount, ref } from "vue";
import { useRouter } from "vue-router";

import VueBootstrapTypeahead from "vue-bootstrap-typeahead";
import Datepicker from "vue3-datepicker";

import useVuelidate from "@vuelidate/core";
import { required, requiredIf, helpers } from "@vuelidate/validators";

const valdidatePhone = (value: string) => {
  if (typeof value === "undefined" || value === null || value === "") {
    return true;
  }
  return /^[0-9]{10,15}$/.test(value);
};

import GcHeader from "@/components/molecules/GcHeader/index.vue";

import {
  IProfile,
  IFrmData,
  IFocus,
  IFormData,
  IError,
  IOption,
  IOptionResponse,
  InputFileEvent,
} from "./interface";

// services
import profileService from "@/services/profile.service";

export default defineComponent({
  components: {
    VueBootstrapTypeahead,
    Datepicker,
    GcHeader,
  },
  setup() {
    const router = useRouter();

    const isLoading = ref<boolean>(false);
    const showModal = ref<boolean>(false);

    const focus = ref<IFocus>({
      placeOfBirth: false,
      provinceId: false,
      institutionId: false,
      industryId: false,
    });

    const frmData = ref<IFrmData>({
      placeOfBirth: "",
      provinceId: "",
      institutionId: "",
      industryId: "",
    });

    const error = ref<IError>({
      name: [],
      place_of_birth: [],
      date_of_birth: [],
      province_id: [],
      gender: [],
      phone: [],
      last_education: [],
      institution_id: [],
      last_education_from_year: [],
      last_education_to_year: [],
      profession: [],
      industry_id: [],
      work_experience_year: [],
      marital_status: [],
    });

    const universityEducation = ref<string[]>([
      "Sarjana (S1)",
      "Magister (S2)",
      "Doktor (S3)",
      "Diploma 1 (D1)",
      "Diploma 2 (D2)",
      "Diploma 3 (D3)",
      "Diploma 4 (D4)",
    ]);

    // get profile
    const initProfile: IProfile = {
      name: "",
      email: "",
      place_of_birth: 0,
      institution_id: 0,
      last_education_from_year: null,
      last_education_to_year: null,
      profession: "",
      industry_id: 0,
      work_experience_year: 0,
      marital_status: "",
      phone: "",
      date_of_birth: null,
      province_id: 0,
      gender: "",
      last_education: "",
    };
    const profileData = ref<IProfile>(initProfile);
    const formData = ref<IFormData>({
      ...initProfile,
      stillEducation: "",
    });
    const rules = {
      name: {
        required: helpers.withMessage("Nama harus diisi", required),
      },
      place_of_birth: {
        required: helpers.withMessage("Tempat lahir harus diisi", required),
      },
      date_of_birth: {
        required: helpers.withMessage("Tanggal lahir harus diisi", required),
      },
      province_id: {
        required: helpers.withMessage("Provinsi harus dipilih", required),
      },
      gender: {
        required: helpers.withMessage("Jenis kelamin harus dipilih", required),
      },
      phone: {
        required: helpers.withMessage("Nomor telepon harus diisi", required),
        valdidatePhone: helpers.withMessage(
          "Nomor telepon tidak valid",
          valdidatePhone
        ),
      },
      last_education: {
        required: helpers.withMessage(
          "Pendidikan terakhir harus dipilih",
          required
        ),
      },
      institution_id: {
        required: helpers.withMessage(
          "Institusi pendidikan harus dipilih",
          requiredIf(
            () =>
              formData.value.last_education !== null &&
              universityEducation.value.includes(formData.value.last_education)
          )
        ),
      },
      last_education_from_year: {
        required: helpers.withMessage(
          "Tahun mulai harus dipilih",
          requiredIf(() => formData.value.last_education !== "Tidak Bersekolah")
        ),
      },
      last_education_to_year: {
        required: helpers.withMessage(
          "Tahun akhir harus dipilih",
          requiredIf(
            () =>
              formData.value.last_education !== "Tidak Bersekolah" &&
              !formData.value.stillEducation
          )
        ),
      },
      profession: {
        required: helpers.withMessage("Profesi harus dipilih", required),
      },
      industry_id: {
        required: helpers.withMessage(
          "Bidang/industri harus dipilih",
          requiredIf(
            () =>
              formData.value.profession !== "Belum/Tidak Bekerja" &&
              formData.value.profession !== "Pelajar/Mahasiswa"
          )
        ),
      },
      work_experience_year: {
        required: helpers.withMessage(
          "Pengalaman Bekerja (Dalam Tahun) harus diisi",
          requiredIf(
            () =>
              formData.value.profession !== "Belum/Tidak Bekerja" &&
              formData.value.profession !== "Pelajar/Mahasiswa"
          )
        ),
      },
    };
    const v$ = useVuelidate(rules, formData);

    const assignError = (paramsError: IError) => {
      if (Object.keys(paramsError).length > 0) {
        error.value = {
          ...paramsError,
        };
      }
    };

    // get account data
    const cityData = ref<IOption[]>([
      {
        value: null,
        text: "Masukkan tempat lahir anda",
      },
    ]);
    const provinceData = ref<IOption[]>([
      {
        value: null,
        text: "Masukkan provinsi tempat anda lahir",
      },
    ]);
    const industryData = ref<IOption[]>([
      {
        value: null,
        text: "Pilih bidang / industri perusahaan anda",
      },
    ]);
    const institutionData = ref<IOption[]>([
      {
        value: null,
        text: "Pilih sekolah / universitas",
      },
    ]);

    const initLastEducationData: IOption[] = [
      {
        value: null,
        text: "Pilih tingkat pendidikan",
      },
      {
        value: "Tidak Bersekolah",
        text: "Tidak Bersekolah",
      },
      {
        value: "Sekolah Dasar (SD)",
        text: "Sekolah Dasar (SD)",
      },
      {
        value: "Sekolah Menengah Pertama (SMP)",
        text: "Sekolah Menengah Pertama (SMP)",
      },
      {
        value: "Sekolah Menengah Atas/Kejuruan (SMA/SMK)",
        text: "Sekolah Menengah Atas/Kejuruan (SMA/SMK)",
      },
      {
        value: "Sarjana (S1)",
        text: "Sarjana (S1)",
      },
      {
        value: "Magister (S2)",
        text: "Magister (S2)",
      },
      {
        value: "Doktor (S3)",
        text: "Doktor (S3)",
      },
      {
        value: "Diploma 1 (D1)",
        text: "Diploma 1 (D1)",
      },
      {
        value: "Diploma 2 (D2)",
        text: "Diploma 2 (D2)",
      },
      {
        value: "Diploma 3 (D3)",
        text: "Diploma 3 (D3)",
      },
      {
        value: "Diploma 4 (D4)",
        text: "Diploma 4 (D4)",
      },
    ];
    const lastEducationData = ref<IOption[]>(initLastEducationData);

    const initGenderData: IOption[] = [
      {
        value: null,
        text: "Pilih jenis kelamin",
      },
      {
        value: "Laki-Laki",
        text: "Laki-Laki",
      },
      {
        value: "Perempuan",
        text: "Perempuan",
      },
    ];
    const genderData = ref<IOption[]>(initGenderData);

    const initProfessionData: IOption[] = [
      {
        value: null,
        text: "Pilih pekerjaan anda saat ini",
      },
      {
        value: "Belum/Tidak Bekerja",
        text: "Belum/Tidak Bekerja",
      },
      {
        value: "Pelajar/Mahasiswa",
        text: "Pelajar/Mahasiswa",
      },
      {
        value: "Pegawai Swasta",
        text: "Pegawai Swasta",
      },
      {
        value: "Wiraswasta",
        text: "Wiraswasta",
      },
      {
        value: "Pegawai Negeri Sipil",
        text: "Pegawai Negeri Sipil",
      },
    ];
    const professionData = ref<IOption[]>(initProfessionData);

    const initMaritalStatusData: IOption[] = [
      {
        value: null,
        text: "Pilih status perkawinan anda",
      },
      {
        value: "",
        text: "Memilih untuk tidak menjawab",
      },
      {
        value: "Lajang/Belum Menikah",
        text: "Lajang/Belum Menikah",
      },
      {
        value: "Sudah Menikah",
        text: "Sudah Menikah",
      },
      {
        value: "Bercerai",
        text: "Bercerai",
      },
    ];
    const maritalStatusData = ref<IOption[]>(initMaritalStatusData);

    const getProfile = async () => {
      const { data } = await profileService.getProfileBParticipant();

      if (data.place_of_birth) await getCity(data.place_of_birth);
      await getProvince("");
      if (data.last_education !== "Tidak Bersekolah" && data.institution_id) {
        await getInstitution(data.institution_id);
      } else {
        await getInstitution("");
      }
      if (
        data.profession !== "Belum/Tidak Bekerja" &&
        data.profession !== "Pelajar/Mahasiswa" &&
        data.industry_id
      ) {
        await getIndustry(data.industry_id);
      } else {
        await getIndustry("");
      }

      if (data) {
        const placeOfBirth = cityData.value.find(
          (item: IOption) => item.value === data.place_of_birth
        );

        const province = provinceData.value.find(
          (item: IOption) => item.value === data.province_id
        );

        let institution = null;
        if (data.last_education !== "Tidak Bersekolah" && data.institution_id) {
          institution = institutionData.value.find(
            (item: IOption) => item.value === data.institution_id
          );
        }

        let industry = null;
        if (
          data.profession !== "Belum/Tidak Bekerja" &&
          data.profession !== "Pelajar/Mahasiswa" &&
          data.industry_id
        ) {
          industry = industryData.value.find(
            (item: IOption) => item.value === data.industry_id
          );
        }

        formData.value = {
          ...formData.value,
          ...data,
          place_of_birth: placeOfBirth ? placeOfBirth.value : "",
          province_id: province ? province.value : "",
          institution_id: institution ? institution.value : "",
          industry_id: industry ? industry.value : "",
          date_of_birth: data.date_of_birth ? new Date(data.date_of_birth) : "",
          last_education_from_year:
            data.last_education !== "Tidak Bersekolah" &&
            data.last_education_from_year
              ? new Date(data.last_education_from_year)
              : null,
          last_education_to_year:
            data.last_education !== "Tidak Bersekolah" &&
            data.last_education_to_year
              ? new Date(data.last_education_to_year)
              : null,
          stillEducation:
            data.last_education_from_year !== null &&
            data.last_education_to_year === null
              ? "true"
              : "",
          work_experience_year:
            data.profession !== "Belum/Tidak Bekerja" &&
            data.profession !== "Pelajar/Mahasiswa"
              ? data.work_experience_year
              : "",
          marital_status:
            data.marital_status === null ? "" : data.marital_status,
        };
        frmData.value = {
          ...frmData.value,
          placeOfBirth: placeOfBirth ? placeOfBirth.text : "",
          provinceId: province ? province.text : "",
          institutionId: institution ? institution.text : "",
          industryId: industry ? industry.text : "",
        };
        profileData.value = {
          ...profileData.value,
          ...data,
        };
      }
    };

    const getCity = async (city: number | string) => {
      const url = isNaN(city as number)
        ? `${process.env.VUE_APP_BACKEND_URL}/city?q=${city}`
        : `${process.env.VUE_APP_BACKEND_URL}/city?id=${city}`;

      const {
        data: { data },
      } = await axios.get(url);
      if (data.length > 0) {
        cityData.value = [
          ...data.map((item: IOptionResponse) => ({
            value: item.id,
            text: item.name,
          })),
        ];
      }
    };

    const searchCity = (city: InputFileEvent) => {
      getCity(city.target.value);
    };

    const updateCity = (city: IOption) => {
      const { value, text } = city;
      frmData.value.placeOfBirth = text;
      formData.value.place_of_birth = value as number;
      v$.value.place_of_birth.$touch();
    };

    const getProvince = async (province: number | string) => {
      const url = isNaN(province as number)
        ? `${process.env.VUE_APP_BACKEND_URL}/province?q=${province}`
        : `${process.env.VUE_APP_BACKEND_URL}/province?id=${province}`;

      const {
        data: { data },
      } = await axios.get(url);
      if (data.length > 0) {
        provinceData.value = [
          ...data.map((item: IOptionResponse) => ({
            value: item.id,
            text: item.name,
          })),
        ];
      }
    };

    const searchProvince = (province: InputFileEvent) => {
      getProvince(province.target.value);
    };

    const updateProvince = (province: IOption) => {
      const { value, text } = province;
      frmData.value.provinceId = text;
      formData.value.province_id = value as number;
      v$.value.province_id.$touch();
    };

    const getInstitution = async (institution: number | string) => {
      const url = isNaN(institution as number)
        ? `${process.env.VUE_APP_BACKEND_URL}/institution?q=${institution}`
        : `${process.env.VUE_APP_BACKEND_URL}/institution?id=${institution}`;

      const {
        data: { data },
      } = await axios.get(url);
      if (data.length > 0) {
        institutionData.value = [
          ...data.map((item: IOptionResponse) => ({
            value: item.id,
            text: item.name,
          })),
        ];
      }
    };

    const searchInstitution = (institution: InputFileEvent) => {
      v$.value.institution_id.$touch();
      getInstitution(institution.target.value);
    };

    const updateInstitution = (institution: IOption) => {
      const { value, text } = institution;
      frmData.value.institutionId = text;
      formData.value.institution_id = value as number;
    };

    const getIndustry = async (industry: number | string) => {
      const url = isNaN(industry as number)
        ? `${process.env.VUE_APP_BACKEND_URL}/industry?q=${industry}`
        : `${process.env.VUE_APP_BACKEND_URL}/industry?id=${industry}`;

      const {
        data: { data },
      } = await axios.get(url);
      if (data.length > 0) {
        industryData.value = [
          ...data.map((item: IOptionResponse) => ({
            value: item.id,
            text: item.name,
          })),
        ];
      }
    };

    const searchIndustry = (industry: InputFileEvent) => {
      getIndustry(industry.target.value);
    };

    const updateIndustry = (industry: IOption) => {
      const { value, text } = industry;
      frmData.value.industryId = text;
      formData.value.industry_id = value as number;
      v$.value.industry_id.$touch();
    };

    onBeforeMount(async () => {
      await getCity(3273);
      await getProfile();
    });

    const logout = () => {
      localStorage.clear();
      router.push("/login");
    };

    const changeLastEducation = () => {
      v$.value.last_education.$touch();

      frmData.value.institutionId = "";

      if (
        formData.value.last_education === null ||
        formData.value.last_education === "Tidak Bersekolah"
      ) {
        formData.value = {
          ...formData.value,
          institution_id: "",
          last_education_from_year: null,
          last_education_to_year: null,
        };
      } else {
        formData.value = {
          ...formData.value,
          institution_id: "",
          last_education_from_year: null,
          last_education_to_year: null,
        };
      }
    };

    const changeProfession = () => {
      v$.value.profession.$touch();

      frmData.value.industryId = "";
      formData.value = {
        ...formData.value,
        industry_id: "",
        work_experience_year: "",
      };
    };

    const updateProfile = async () => {
      v$.value.$validate();
      const isInvalid = v$.value.$invalid;

      if (!isInvalid) {
        isLoading.value = true;

        try {
          await profileService.updateProfile({
            ...formData.value,
            date_of_birth: formData.value.date_of_birth
              ? moment(formData.value.date_of_birth).format("YYYY-MM-DD")
              : "",
            last_education_from_year: formData.value.last_education_from_year
              ? moment(formData.value.last_education_from_year).format(
                  "YYYY-01-01"
                )
              : "",
            last_education_to_year:
              !formData.value.stillEducation &&
              formData.value.last_education_to_year
                ? moment(formData.value.last_education_to_year).format(
                    "YYYY-01-01"
                  )
                : "",
          });

          showModal.value = true;
        } catch (e: unknown) {
          const { response } = e as AxiosError;

          if (Object.keys(response?.data?.errors).length > 0) {
            assignError(response?.data?.errors);
          } else {
            alert(response?.data?.message);
          }
        }

        isLoading.value = false;
      }
    };

    const updateStillEducation = () => {
      if (formData.value.stillEducation === "true") {
        formData.value.last_education_to_year = null;
      }
    };

    const closeModal = () => {
      showModal.value = false;
      router.push("/home");
    };

    return {
      v$,
      isLoading,
      showModal,
      closeModal,
      focus,
      frmData,
      universityEducation,
      searchCity,
      updateCity,
      searchProvince,
      updateProvince,
      searchInstitution,
      updateInstitution,
      searchIndustry,
      updateIndustry,
      error,
      router,
      profileData,
      formData,
      cityData,
      provinceData,
      industryData,
      institutionData,
      lastEducationData,
      genderData,
      professionData,
      maritalStatusData,
      logout,
      changeLastEducation,
      changeProfession,
      updateProfile,
      updateStillEducation,
    };
  },
});
